import React, { useState, FC } from "react";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom";
import { AuthFormWrapper, Heading, Link } from "./styled";

import styles from "./style.module.css";

const ResetPasswordForm: FC<{
  isResetPasswordInProgress: boolean;
}> = ({ isResetPasswordInProgress }) => {

  return (
    <>
      
      <Form.Item
        name="email"
        label="Your corporate email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
        className={styles.formField}
      >
        <Input className={styles.input} />
      </Form.Item>
      
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        className={styles.formField}
      >
        <Input.Password className={styles.input} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.authFormSubmit}
          loading={isResetPasswordInProgress}
        >
Reset Password        </Button>
      </Form.Item>
      
    </>
  );
};


const SplashScreen: FC<{

}> = () => {

  return (
    <>
      <p>Password has been successfully reset! Please login from a new page</p>
      
    </>
  );
};



// @ts-ignore
export const PasswordResetAuthForm = (props) => {
  const {
    passwordReset,
    onPasswordReset,
    isPasswordResetInProgress,
    showSplashScreen,
    setShowSplashScreen,
    
  } = props;
  const [form] = Form.useForm();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get("t");
  const onFinish = (values: any) => {
    
    const {
      email: emailAddress,
      password,
    } = values      
      passwordReset({ emailAddress, token, password },onPasswordReset);
  };
  const forgotPasswordForm = (
    <>
      {showSplashScreen? (
          <SplashScreen />
      ):(<ResetPasswordForm isResetPasswordInProgress={isPasswordResetInProgress}/>)}
    </>
  )

  return (
    <AuthFormWrapper>
      <Heading>
        Reset Password
      </Heading>
      
      <Form
        form={form}
        name="reset"
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        {forgotPasswordForm}
      </Form>
    </AuthFormWrapper>
  );
};
