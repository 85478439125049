import React, { useState, FC } from "react";
import { Form, Input, Button } from "antd";

import { AuthFormWrapper, Heading, Link } from "./styled";

import styles from "./style.module.css";
import { sign } from "crypto";
import { useAuth0 } from "@auth0/auth0-react";
import {config} from "../../conf"

type FormStates = "sign-up" | "sign-in" | "forgot-password";

const SignUp: FC<{
  toggleFormState: () => void;
  isSignUpInProgress: boolean;
}> = ({ toggleFormState, isSignUpInProgress }) => {

  return (
    <>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please input your name",
          },
        ]}
        className={styles.formField}
      >
        <Input className={styles.input} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Your corporate email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
        className={styles.formField}
      >
        <Input className={styles.input} />
      </Form.Item>
      <Form.Item name="company" label="Company" 
      rules={[
        {
          required: true,
          message: "Please input your company name!",
        },
      ]}
      className={styles.formField}>
        <Input className={styles.input} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        className={styles.formField}
      >
        <Input.Password className={styles.input} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.authFormSubmit}
          loading={isSignUpInProgress}
        >
          Sign up
        </Button>
      </Form.Item>
      <p>
        Already have an account?
        <Link onClick={toggleFormState}>&nbsp;&nbsp;Login</Link>
      </p>
    </>
  );
};


const SignIn: FC<{
  toggleFormState: () => void;
  setForgotPasswordFormState: () => void;
  isSignInInProgress: boolean;
}> = ({ toggleFormState, setForgotPasswordFormState, isSignInInProgress }) => {
  const { loginWithRedirect } = useAuth0();
  const SignInWithSSO = async () => {
    loginWithRedirect( {organization: config.auth0_org});
  }
  return (
    <>
      <Form.Item
        name="email"
        label="Your corporate email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
        className={styles.formField}
      >
        <Input className={styles.input} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        className={styles.formField}
      >
        <Input.Password className={styles.input} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSignInInProgress}
          className={styles.authFormSubmit}
        >
          Login
        </Button>
      </Form.Item>
      <p>
        Don't have an account
        <Link onClick={toggleFormState}>&nbsp;&nbsp;Sign up</Link>
      </p>
      <p>
        Forgot Password?
        <Link onClick={setForgotPasswordFormState}>&nbsp;&nbsp;Reset Password</Link>
      </p>
      {/* <Button
          type="primary"
          onClick={() => SignInWithSSO()}
        >
          Sign-in with SSO
        </Button> */}
    </>
  );
};

const ForgotPassword: FC<{
  toggleFormState: () => void;
  isForgotPasswordInProgress: boolean;
}> = ({ toggleFormState, isForgotPasswordInProgress }) => {
  
  return (
    <>
      <Form.Item
        name="email"
        label="Your corporate email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
        className={styles.formField}
      >
        <Input className={styles.input} />
      </Form.Item>
      
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isForgotPasswordInProgress}
          className={styles.authFormSubmit}
        >
          Reset Password
        </Button>
      </Form.Item>
      
    </>
  );
};


const SplashScreen: FC<{
  toggleFormState: () => void;
  setShowSplashScreen: () => void;
}> = ({ toggleFormState, setShowSplashScreen }) => {
  const backToLogin = () => {
    toggleFormState();
    setShowSplashScreen();
  };
  return (
    <>
      <p>You account is created Successfully!</p>
      <p>
        Please log in to your account!
        <Link onClick={backToLogin}>&nbsp;&nbsp;Login</Link>
      </p>
    </>
  );
};

const ForgotPasswordSplashScreen: FC<{
  toggleFormState: () => void;
  setShowSplashScreen: () => void;
}> = ({ toggleFormState, setShowSplashScreen }) => {
  const backToLogin = () => {
    toggleFormState();
    setShowSplashScreen();
  };
  return (
    <>
      <p>If an account exists with that email, you will receive a link in your email to reset your passwrd!</p>

    </>
  );
};

// @ts-ignore
export const AuthForm = (props) => {
  const {
    signIn,
    signUp,
    forgotPassword,
    onSign,
    onSignUp,
    onForgotPassword,
    isSignUpInProgress,
    isSignInInProgress,
    isForgotPasswordInProgress,
    showSplashScreen,
    setShowSplashScreen,
    showPasswordResetSplashScreen,
    setShowPasswordResetSplashScreen
  } = props;
  const [form] = Form.useForm();
  const [formState, setFormState] = useState<FormStates>("sign-in");
  const [errorSignIn, setErrorSignIn] = useState("");

  const errorCb = (status: number) => {
    if (status >= 500) setErrorSignIn("Something Went Wrong");
    else setErrorSignIn("Invalid username or password");
  };

  const onFinish = (values: any) => {
    if (formState === "sign-up") {
      const {
        name,
        company: companyName,
        email: emailAddress,
        password,
      } = values;
      signUp({ name, companyName, emailAddress, password }, onSignUp);
    } else if (formState === "sign-in") {
      const { email: emailAddress, password } = values;
      if (errorSignIn) {
        setErrorSignIn("");
      }
      signIn({ emailAddress, password }, onSign, errorCb);
    } else {
      const { email: emailAddress} = values;
      
      forgotPassword({ emailAddress }, onForgotPassword, errorCb);
    }
  };

  const setForgotPasswordFormState = () => {
    setFormState("forgot-password");
  }
  const toggleFormState = () => {
    switch (formState) {
      case "sign-in":
        setFormState("sign-up");
        if (errorSignIn) {
          setErrorSignIn("");
        }
        break;
      case "sign-up":
        setFormState("sign-in");
        break;
      
        

    }
  };
  const signInForm =(<SignIn
  toggleFormState={toggleFormState}
  isSignInInProgress={isSignInInProgress}
  setForgotPasswordFormState={setForgotPasswordFormState}
/>);
  const signUpForm = (
    <>
      {showSplashScreen ? (
        <SplashScreen
          setShowSplashScreen={setShowSplashScreen}
          toggleFormState={toggleFormState}
        />
      ) : (
        <SignUp
          toggleFormState={toggleFormState}
          isSignUpInProgress={isSignUpInProgress}
        />
      )}
    </>
  );
  const forgotPasswordForm = (
    <>
      {showPasswordResetSplashScreen? (
          <ForgotPasswordSplashScreen setShowSplashScreen={setShowPasswordResetSplashScreen} toggleFormState={toggleFormState}/>
      ):(<ForgotPassword toggleFormState={toggleFormState} isForgotPasswordInProgress={isForgotPasswordInProgress}/>)}
    </>
  )
  let formToShow;
  if (formState === "sign-up") 
    formToShow = signUpForm;
  else if  (formState === "sign-in") {
    formToShow = signInForm;
  } else
    formToShow = forgotPasswordForm

  return (
    <AuthFormWrapper>
      <Heading>
        {" "}
        {formState === "sign-up"
          ? showSplashScreen
            ? "Almost Done"
            : "Sign up"
          : "Login"}
      </Heading>
      {errorSignIn && <p className={styles.signinError}>{errorSignIn}</p>}
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        {formToShow}
      </Form>
    </AuthFormWrapper>
  );
};
