// @ts-nocheck

import React, { FC, useEffect, useState } from "react";
import { Logo } from "../../components/logo";
import { AuthForm } from "../../components/auth-form";
import {PasswordResetAuthForm} from "../../components/reset-password-form";
import { PageWrapper, Header } from "./styled";
import { useAuth } from "../../controllers/auth-controller";
import { useResetPasswordService } from "../../services/resetpassword";
import { useNavigate } from "react-router-dom";

export const ResetPasswordPage: FC<{}> = () => {
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  
  const [isResetPasswordInProgress, resetPasswordResponse, resetPassword] = useResetPasswordService();
  let navigate = useNavigate();
  const onPasswordReset = () => {
    setShowSplashScreen(true);
    };

return (
    <>
      <PageWrapper>
        <Header>
          <Logo />
        </Header>
        
        <PasswordResetAuthForm
          passwordReset={resetPassword}
          isPasswordResetInProgress={isResetPasswordInProgress}
          onPasswordReset={onPasswordReset}
          showSplashScreen={showSplashScreen}
          setShowSplashScreen={setShowSplashScreen}
        />
      </PageWrapper>
    </>
  );
};
