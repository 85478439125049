import React from "react";
import "antd/dist/antd.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Global, css } from "@emotion/react";
import { LoginPage } from "./pages/login-page";
import {ResetPasswordPage} from "./pages/reset-password";
import { AuthedPage } from "./pages/authed-page";
import { DashboardPage } from "./pages/dashboard-page";
import { WorkflowsPage } from "./pages/workflows-page";
import { TaskExecutorsPage } from "./pages/task-executors-page";
import { TaskExecutorsDetail } from "./pages/task-executors-detail";
import { NoMatchPage } from "./pages/no-match";
import { WorkflowsDetail } from "./pages/workflows-detail";
import { WorkflowsHistory } from "./pages/workflows-history";
import { AuthController } from "./controllers/auth-controller";
import { PrivateRoute } from "./controllers/private-route";
import { Settings } from "./pages/settings-page";
import { Auth0Login } from "./pages/auth0-login-page";
import { WorkspaceSettingsPage } from "./pages/workspace-settings-page";

export default function App() {
  return (
    <AuthController>
      <Router>
        <Global
          styles={css`
                    body {
                        font-family: 'Nunito Sans', sans-serif; !important
                        background-color: #eef0f4 !important;
                    }
                `}
        />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/passwordReset" element={<ResetPasswordPage />} />
          <Route path="/auth0-login" element={<Auth0Login />} />
          <Route
            path="/workspaces"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <DashboardPage />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/workflows"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <WorkflowsPage />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/taskexecutors"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <TaskExecutorsPage />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/taskexecutors/:taskexecutorsId"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <TaskExecutorsDetail />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/workflows/:workflowsId"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <WorkflowsDetail />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/workflows/:workflowsId/runhistory"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <WorkflowsHistory />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <Settings />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route
            path="/workspacesettings/:workspaceId"
            element={
              <PrivateRoute>
                <AuthedPage>
                  <WorkspaceSettingsPage />
                </AuthedPage>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NoMatchPage />}>
          </Route>
        </Routes>
      </Router>
    </AuthController>
  );
}
